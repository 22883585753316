<template>
  <div class="list">
    <div class="article_form">
      <div class="article_form_title">库存信息</div>
      <div class="article_commity">
        <div class="list_b" v-if="$store.state.User.jibieId==0">
          <ul>
            <li>
              <p>切换门店：</p>
              <select v-model="form.fenpeimendianId" @change="getProductList();getData(1)">
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <div class="clear"></div>
          </ul>
        </div>
        <div class="commity_kucun">
          <div class="commity_kucun_scrll">
            <div :class="item.num<6?'commity_kucun_a quehuo':'commity_kucun_a'" v-for="item in commityNumList" :key="item" @click="LoyiBackDataForCommity(item)">
              <div class="commity_kucun_a_1"><div class="commity_kucun_a_1_1">{{item.comName}}</div></div>
              <div class="commity_kucun_a_2"><strong>{{item.num}}</strong>{{item.unit}}</div>
            </div>
          </div>
        </div>
        <div class="list_c">
          <Page :pageData="pageData" @resetData="resetData"></Page>
          <div class="clear"></div>
        </div>
      </div>
      <div class="article_form_title">退货明细</div>
      <div class="article_jz">
        <div class="article_jz_b" style="width:100%; padding:10px">
          <div class="article_jz_a_1">
            <ul>
              <li class="hover">退货明细</li>
            </ul>
          </div>
          <div class="article_jz_b_1" style="height:310px">
            <div class="article_jz_b_1_1" style="height:290px">
              <table class="drags_3">
                <colgroup>
                  <col width="60">
                  <col>
                  <col width="13%">
                  <col width="13%">
                  <col width="10%">
                  <col width="15%">
                  <col width="10%">
                </colgroup>
                <thead>
                  <tr>
                    <th style="text-align:center">序号</th>
                    <th>名称</th>
                    <th style="text-align:center">单价</th>
                    <th style="text-align:center">数量</th>
                    <th style="text-align:center">单位</th>
                    <th style="text-align:center">总价</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody class="vip_user_list">
                  <tr v-for="(item,i) in inventoryInList" :key="i">
                    <td style="text-align:center">{{i+1}}</td>
                    <td>{{item.comName}}</td>
                    <td style="text-align:center">
                      <p><input type="number" v-model="item.chengben" @change="tongyijisuan"></p>
                    </td>
                    <td style="text-align:center">
                      <p><input type="number" v-model="item.num" @change="setMaxNum(item)"></p>
                    </td>
                    <td style="text-align:center">{{item.unit}}</td>
                    <td style="text-align:center">{{parseFloat((item.num*item.chengben).toFixed(2))}}</td>
                    <td>
                      <div class="buttonGroup">
                        <button type="button" class="err" @click="delItem(i)">删除</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="article_jz_b_2" style="height:119px">
            <div class="article_jz_b_2_1">
              <div class="article_jz_b_2_1_title">退货金额</div>
              <div class="article_jz_b_2_1_2"><span class="article_left_title">总计金额：</span>￥<strong>{{fukuanObj.zongjijine}}</strong></div>
              <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">实退金额：</span>￥<input type="number" v-model="fukuanObj.shifukuan"></div>
              <div class="article_jz_b_2_1_2"><span class="article_left_title">退款方式：</span>
                <select v-model="fukuanObj.zhifufangshi">
                  <option value="1">不入账</option>
                  <option v-for="item in zffsList" :key="item.id" :value="item.id">{{item.name}}</option>
                </select>
              </div>
            </div>
            <div class="article_jz_b_2_1">
              <div class="article_jz_b_2_1_title">备注</div>
              <div class="article_jz_b_2_1_1" style="text-align:center; "><textarea v-model="fukuanObj.info"></textarea></div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_b_4">
        <button class="settab" @click="subData">退货</button>
        <button class="remole" style="margin-left:50px" @click="closedWindow">返回</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import {zffsList} from '@/data/data'
// import {deepCopy,testStr,sortFn} from '@/utils/util'
export default {
  name: 'inventoryOut',
  data() {
    return {
      zffsList,
      storeList:[],
      productList:[],
      commityNumList:[],
      inventoryInList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
      },
      fukuanObj:{
        zongjijine:0,
        shifukuan:0,
        zhifufangshi:1,
        info:''
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    subData(){
      if(this.inventoryInList.length){
        axios.post('/commity/inventoryOut',{...this.form,...this.fukuanObj,comList:this.inventoryInList}).then((res) => {
          if(res.code===5000){
            this.$Mesg({content: res.msg,errOrSuc: 'suc'})
            this.getData(1)
            this.inventoryInList=[]
            this.tongyijisuan()
          }else{
            this.$Mesg({content: res.msg,errOrSuc: 'err'})
          }
        })
      }else{
        this.$Mesg({content:"退货明细无内容！",errOrSuc: 'err'})
      }
    },
    setMaxNum(item){
      if(item.num>item.maxNum) {
        item.num=item.maxNum
        this.$Mesg({content:"退货数量不能大于库存数量！",errOrSuc: 'war'})
      }
      this.tongyijisuan()
    },
    tongyijisuan(){
      let zongjia=0
      this.inventoryInList.forEach(e=>{
        zongjia+=e.num*e.chengben
      })
      this.fukuanObj.zongjijine=parseFloat(zongjia.toFixed(2))
      this.fukuanObj.shifukuan=this.fukuanObj.zongjijine
    },
    delItem(i){
      this.inventoryInList.splice(i,1)
      this.tongyijisuan()
    },
    LoyiBackDataForCommity(item){
      let pro= this.productList.find(e=>e.id==item.comId)
      if(pro){
        this.inventoryInList.push({
          id:item.id,
          comId:pro.id,
          comName:pro.name,
          num:1,
          maxNum:item.num,
          unit:pro.unit,
          chengben:pro.chengben,
        })
        this.tongyijisuan()
      }
    },
    init(){
      this.getStore()
      this.getData()
      this.getProductList()
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/commity/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.commityNumList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    getProductList(){
      axios.post('/product/list',this.form).then((res) => {
        if(res.code===5000){
          this.productList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    closedWindow(){
      this.$router.back()
    },
  },
}
</script>
